import React from 'react';
import AiOverview from '../connect-front/src/components/automation/AiOverview';

export default function AiPage({ keyPairs }) {

	return (
		<>
			{keyPairs.map((keyPair, index) => (
				<React.Fragment key={index}>
					<AiOverview property={keyPair.locationid} aiControls={keyPair.aiControls} />
					<div className='page-break' />
				</React.Fragment>
			))}
		</>
	);
}
