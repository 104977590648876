import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import { format as formatDate, differenceInMinutes } from 'date-fns';
import chroma from 'chroma-js';
import { addDays } from 'date-fns';

import { GRAPH_TYPES, GRAPH_INTERVAL_TYPES, UNIT_CLASSIFICATIONS } from '../constants';
import * as colors from '../colors';
import { getFormattedNumber, clamp, getTruncatedStr, getInterpolatedData } from '../utility-functions';
import i18n from '../i18n';

const GRAPH_COLORS = { recentData: '#007FB2', comparisonData: '#D0DADF' };
const TIME_FMT = '%Y-%m-%dT%H:%M:%S.%LZ';

/**
 * Parse through datasets for specific graphtypes and return the largest value found on the Y-axis
 * @param {GRAPH_TYPES} graphType : Type of graph for which the data is meant
 * @param {Array} data : Data to be parsed
 * @param {Array} valueKeys : Contains the names of the keys in the data, only used by barcharts, [String, ...]
 */
function getDataValueRange(graphType, data, valueKeys, alarmValue) {
	let r = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };

	if (graphType === GRAPH_TYPES.line) {
		data.forEach(timespan => {
			timespan.data.forEach(dataPoint => {
				if (typeof dataPoint.y === 'number') {
					if (dataPoint.y < r.min) r.min = dataPoint.y;
					if (dataPoint.y > r.max) r.max = dataPoint.y;
				}
			});
		});
	} else if (graphType === GRAPH_TYPES.bar) {
		data.forEach(dataPoint => {
			valueKeys.forEach(key => {
				if (typeof dataPoint[key] === 'number') {
					if (dataPoint[key] < r.min) r.min = dataPoint[key];
					if (dataPoint[key] > r.max) r.max = dataPoint[key];
				}
			});
		});
	}

	if (alarmValue > r.max) r.max = alarmValue;
	else if (alarmValue < r.min) r.min = alarmValue;

	if (r.min === Number.MAX_SAFE_INTEGER) r.min = 0;
	if (r.max === Number.MIN_SAFE_INTEGER) r.max = 0;

	return r;
}

function newGetDataValueRange(graphType, data, valueKeys, alarmValue) {
	let r = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };

	if (data.every((item) => item.unitVal === null)) {
		r.min = 0;
		r.max = 0;
	}
	else {
		const min_value = Math.min(...data.map((item) => item.unitVal))
		const max_value = Math.max(...data.map((item) => item.unitVal))
		r.min = min_value
		r.max = max_value

	}

	if (alarmValue > r.max) r.max = alarmValue;
	else if (alarmValue < r.min) r.min = alarmValue;

	if (r.min === Number.MAX_SAFE_INTEGER) r.min = 0;
	if (r.max === Number.MIN_SAFE_INTEGER) r.max = 0;

	return r;
}

/**
 * Suggest min/max values for a chart
 * @param {{min: number, max: number}} range : Min/Max values of the data to be rendered in the chart
 * @returns {{min: number, max: number}} : Suggested min/max range for the chart
 */
export function getDataDisplayRange(range) {
	function minFormula(val) {
		const offsetVal = val - 0.1 * Math.abs(val);
		if (val >= 0) return Math.floor(offsetVal);
		else return offsetVal;
	}

	function maxFormula(val) {
		const offsetVal = val + 0.1 * Math.abs(val);
		if (val >= 0) return offsetVal;
		else return Math.ceil(offsetVal) + 0.05; // 0.05 to avoid the 0-grid-line being clipped
	}

	return { min: minFormula(range.min), max: maxFormula(range.max) };
}

/**
 * Get the offset from 0 at which the display-range starts
 * @param {{min: number: max: number}} range : Range of data-values of the chart for which the offset is to be produced
 */
function getDisplayRangeOffset(range) {
	return range.min > 0 ? range.min : range.max < 0 ? range.max : 0;
}

/**
 * Produces a linear gradient appropriate for the given value-range
 * @param {{min: number: max: number}} range : Range of data-values of the chart for which the gradient is to be produced
 * @param {{id: string, color: string, opacity: string}[]} settings : Override the defaults with optional parameters
 */
function getLinearGradients(range, settings) {
	const DEFAULTS = Object.freeze({ id: 'linearGradient', color: 'inherit', opacity: '0' });

	const gradients = [];
	const zeroOffset = (range.max - range.min) * 0.05; // Avoid problems with some graphs that are offset for aesthetical purposes

	// [{}] makes sure loop is run at least once
	for (const set of settings || [{}]) {
		const color = set.color || DEFAULTS.color;
		const opacity = set.opacity || DEFAULTS.opacity;

		if (range.min >= -zeroOffset)
			gradients.push([
				{ offset: 0, color: 'inherit' },
				{ offset: 100, color, opacity },
			]);
		else if (range.max <= zeroOffset)
			gradients.push([
				{ offset: 0, color, opacity },
				{ offset: 100, color: 'inherit' },
			]);
		else
			gradients.push([
				{ offset: 0, color: 'inherit' },
				{ offset: 100 * clamp(range.max / (range.max - range.min || 0.001), 0.001, 0.999), color, opacity },
				{ offset: 100, color: 'inherit' },
			]);
	}

	return gradients.map((gra, i) => linearGradientDef(settings?.[i]?.id || DEFAULTS.id, gra));
}

/**
 * Generates appropriate threshold-markers
 * @param {array} data : The sensor-data that will be displayed in the graph
 * @param {object} sensor : Sensor to generate markers for
 * @param {{min: number, max: number}} displayRange : Y-interval for which the chart will be drawn
 * @returns {array} : Markers ready to be passed to a Nivo-chart
 */
function getThresholdMarkers(data, sensor, displayRange) {
	const hasData = data?.some(dat => {
		// Check if the data is for a line-chart (has 'data' key) or a bar-chart
		if ('data' in dat) return dat.data.some(dat => typeof dat.y === 'number');
		else return typeof dat[GRAPH_INTERVAL_TYPES.current] === 'number' || typeof dat[GRAPH_INTERVAL_TYPES.former] === 'number';
	});

	const markers = [];
	if (hasData) {
		if (
			typeof sensor?.lowerthreshold === 'number' &&
			sensor.lowerthreshold >= displayRange.min &&
			sensor.lowerthreshold <= displayRange.max
		)
			markers.push({
				axis: 'y',
				value: sensor.lowerthreshold,
				lineStyle: {
					stroke: '#ff5014cc',
					strokeWidth: 3,
				},
				legend: (typeof sensor.lowerthreshold === 'number' && sensor.lowerthresholdLabel) || '',
				legendPosition: 'top-left',
				textStyle: {
					fontSize: '90%',
					fontWeight: '600',
				},
			});

		if (
			typeof sensor?.upperthreshold === 'number' &&
			sensor.upperthreshold >= displayRange.min &&
			sensor.upperthreshold <= displayRange.max
		)
			markers.push({
				axis: 'y',
				value: sensor.upperthreshold,
				lineStyle: {
					stroke: '#ff5014cc',
					strokeWidth: 3,
				},
				legend: (typeof sensor.upperthreshold === 'number' && sensor.upperthresholdLabel) || '',
				legendPosition: 'top-left',
				textStyle: {
					fontSize: '90%',
					fontWeight: '600',
				},
			});
	}

	return markers;
}

/**
 * Produce a linearly interpolated output between outThresholds depending to the inValue, which is clamped to be within the inTresholds
 * @param {Number} inValue : Value to be used for the interpolation
 * @param {Object} inThresholds : Input will be clamped to this range, {min: Number, max: Number}
 * @param {Object} outThresholds : Output will be clamped to this range, {min: Number, max: Number}
 */
function interpolateNumber(inValue, inThresholds, outThresholds) {
	const inScale = inThresholds.max - inThresholds.min;
	const outScale = outThresholds.max - outThresholds.min;

	const inProgress = Math.min(1, Math.max(0, inValue - inThresholds.min) / inScale);
	return inProgress * outScale + outThresholds.min;
}

/**
 * Will draw a dot or 'H' mark at x,y coordinate
 * @param {object} layerProps : Supplied by Nivo when called through `layers` property
 * @param {{date: Date, value: number}} alarm : Info about the alarm to be marked on the chart
 * @param {GRAPH_TYPES} graphType : On what kind of graph is the mark drawn
 * @param {{min: number, max: number} | undefined} displayRange : Value-range displayed in the chart, only required for bar-charts
 * @param {Object (INTERVAL_VALUES)} timeOptions : Information about the time-scale being drawn
 */
function drawAlarmMark(layerProps, alarm, graphType, displayRange, timeOptions) {
	if (!alarm) return;
	const yearFmtStr = formatDate(alarm.date, 'MMM yyyy');
	const monthFmtStr = formatDate(alarm.date, 'd MMM');
	const dayFmtStr = formatDate(alarm.date, 'HH:mm');

	if (graphType === GRAPH_TYPES.line) {
		let dataPoint = undefined;
		// date/day selected. This assumes .data and .series are parallel.
		if (layerProps.series[layerProps.series.length - 1]?.data.length === 24) {
			const searchArea = layerProps.data[layerProps.series.length - 1]?.data;

			const min = searchArea.reduce((iMin, item, index) => {
				const currentDiff = Math.abs(differenceInMinutes(alarm.date, new Date(item.formattedDate.replace(' ', 'T'))));
				const oldDiff = Math.abs(differenceInMinutes(alarm.date, new Date(searchArea[iMin].formattedDate.replace(' ', 'T'))));
				return currentDiff < oldDiff ? index : iMin;
			}, 0);
			dataPoint = layerProps.series[layerProps.series.length - 1]?.data[min];
		} else
			dataPoint = layerProps.series[layerProps.series.length - 1]?.data?.find(
				item => item.data.x === yearFmtStr || item.data.x === monthFmtStr || item.data.x === dayFmtStr
			);

		if (dataPoint) {
			return (
				<>
					<defs>
						<filter id='shadow' x='-50' y='-50' width='100' height='100'>
							<feOffset result='offOut' in='SourceAlpha' dx='0' dy='0' />
							<feGaussianBlur result='blurOut' in='offOut' stdDeviation='2' />
							<feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
						</filter>
					</defs>
					<circle
						stroke='#ff0000'
						strokeWidth={2}
						fillOpacity='0'
						key={'alarmDot'}
						cx={dataPoint.position.x}
						cy={layerProps.yScale(alarm.value)}
						r={9}
						filter='url(#shadow)'
					/>
					<text
						fill='red'
						fontSize={12}
						dominantBaseline='hanging'
						textAnchor='middle'
						x={dataPoint.position.x}
						y={layerProps.yScale(alarm.value) + 15}
						filter='url(#shadow)'
					>
						{i18n.t('generic.alarm_one')}
					</text>
				</>
			); // TODO: Check plural
		}
	} else {
		const dateFormatting = timeOptions.days === 1 ? 'yyyy-MM-dd hh:mm' : timeOptions.dateFormat;
		const alarmStr = formatDate(alarm.date, dateFormatting);
		const xPos = layerProps.bars.find(item => alarmStr === formatDate(new Date(item.data.data.Datum), dateFormatting));

		if (xPos) {
			const commonProps = { strokeWidth: 2.5, stroke: 'red', filter: 'url(#shadow)' };
			const height = 10;
			const width = layerProps.bars[0].width + 3;

			return (
				<svg x={xPos?.x} y={layerProps.yScale(alarm.value - getDisplayRangeOffset(displayRange))} overflow='visible'>
					<defs>
						<filter id='shadow' x='-50' y='-50' width='100' height='100' filterUnits='userSpaceOnUse'>
							<feOffset result='offOut' in='SourceAlpha' dx='0' dy='0' />
							<feGaussianBlur result='blurOut' in='offOut' stdDeviation='2' />
							<feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
						</filter>
					</defs>
					<line x1={-3} x2={-3} y1={0} y2={height} {...commonProps} />
					<line x1={width} x2={width} y1={0} y2={height} {...commonProps} />
					<line x1={-3} x2={width} y1={height / 2} y2={height / 2} {...commonProps} />
					<text
						x={width / 2}
						y={height + height * 0.25}
						dominantBaseline='hanging'
						textAnchor='middle'
						fill='red'
						fontSize={12}
						filter='url(#shadow)'
					>
						{i18n.t('generic.alarm_one')}
					</text>
				</svg>
			); // TODO: Check plural
		}
	}
}

// Settings to be applied to AccumulatedChart and AccumulatedSumChart, only settings that are same in both
const chartSettings = {
	data: data => data || [], // If no valid data exists an empty space will be rendered
	xScale: { type: 'point' },
	yScale: (dataInfo, isSumChart = false) => {
		if (!isSumChart)
			return {
				type: 'linear',
				stacked: false,
				min: Math.max(0, Math.floor(dataInfo.min * 0.9) - 0.51), // 0.51 because Nivo snaps up when deciding where to place vertical lines
				max: Math.ceil(dataInfo.max * 1.1) + 0.49,
			};
		else
			return {
				type: 'linear',
				stacked: false,
				min: dataInfo.min - 0.1 * (dataInfo.max - dataInfo.min),
				max: dataInfo.max + 0.1 * (dataInfo.max - dataInfo.min),
			};
	},
	enableGridX: false,
	getMinValue: dataInfo => dataInfo.min - 0.1 * (dataInfo.max - dataInfo.min),
	pointSize: dataGrp => {
		let pointSize = 1; // Throws 'Error: <circle> attribute r: A negative value is not valid. ("-0.011072433824387034")' in console sometimes when set to 0, but without noticeably affecting the application
		let minConsecPoints = Number.MAX_SAFE_INTEGER;

		for (const grp of dataGrp || [])
			if (grp.data?.length) {
				const segment = grp.data.reduce(
					(sum, cur) =>
						typeof cur.y === 'number'
							? { min: sum.min, cur: sum.cur + 1 }
							: { min: Math.min(sum.min, sum.cur || sum.min), cur: 0 },
					{ min: Number.MAX_SAFE_INTEGER, cur: 0 }
				);
				const segmentMin = Math.min(segment.min, segment.cur || segment.min);

				minConsecPoints = Math.min(minConsecPoints, segmentMin);
			}

		if (minConsecPoints === 1) pointSize = 5;
		return pointSize;
	},
};

/**
 * Nivo-linechart meant to visalize data in the history-tab of property-node
 * @param {Array} data : Data to be shown
 * @param {Object} sensorInfo : Information about the sensor to which the data belongs, {unit: String, lowerthreshold: Number, upperthreshold: Number, lowerthresholdLabel: String, upperthresholdLabel: String}
 * @param {Array} yRange : Override the default yScale by forcing the specific range of Y-values to in focus, [min, max]
 * @param {Number} maxLegends : How many legends are allowed before legends are disabled all together
 * @param {Object} margin : Margins applied to the Nivo-chart, specifying the amount of spacing around it and room for legends, etc., {top, right, bottom, left: Number (pixels)}
 * @param {Object} chartProps : Props that will be passed directly to the chart
 * @param {{date: Date, value: Number}} alarm : Containing alarm value and date
 * @param {Object (INTERVAL_VALUES)} timeOptions : Information about the time-scale being drawn
 * @param {Boolean} dynamicTooltip : If the tooltip should appear above or below the current point
 */
function HistoryLineChart(props) {
	let suggestedDisplayRange
	if (props.selectedSensor) {
		suggestedDisplayRange = getDataDisplayRange(newGetDataValueRange(GRAPH_TYPES.line, props.data || [], undefined, props.alarm?.value));
	}
	else {
		suggestedDisplayRange = getDataDisplayRange(getDataValueRange(GRAPH_TYPES.line, props.data || [], undefined, props.alarm?.value));
	}
	const displayRange = {
		min: props.yRange?.[0] !== undefined ? props.yRange[0] : suggestedDisplayRange.min,
		max: props.yRange?.[1] !== undefined ? props.yRange[1] : suggestedDisplayRange.max,
	};

	return (
		<ResponsiveLine
			data={
				//!TEMP - Only interpolate while data is unstable
				props.data?.map(type => ({
					...type,
					data: getInterpolatedData(
						type.data,
						dat => dat.y,
						(dat, val) => (dat.y = val)
					),
				})) || []
			} // If no valid data exists an empty space will be rendered
			margin={{
				top: props.margin?.top !== undefined ? props.margin.top : 0,
				right: props.margin?.right !== undefined ? props.margin.right : 168,
				bottom: props.margin?.bottom !== undefined ? props.margin.bottom : 40,
				left: props.margin?.left !== undefined ? props.margin.left : 50,
			}}
			xFormat={`time:${TIME_FMT}`}
			xScale={{
				type: 'time',
				format: TIME_FMT,
				min: props.data?.[1]?.data?.[0]?.x,
				max: props.data?.[1]?.data?.[props.data?.[1]?.data?.length - 1]?.x,
			}}
			yScale={{
				type: 'linear',
				stacked: false,
				min: displayRange.min,
				max: window.location.pathname.includes('automation') ? 'auto' : displayRange.max,
			}}
			curve='monotoneX'
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: value => formatDate(value, props.timeOptions.dateFormat || 'yyyy-MM-dd HH:mm'),
				orient: 'bottom',
				tickValues: props.timeOptions?.chartTickCount,
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
				legendOffset: 32,
				legendPosition: 'middle',
			}}
			axisLeft={{
				orient: 'left',
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: props.sensorInfo?.unit || '',
				legendOffset: -40,
				legendPosition: 'middle',
				format: val => getFormattedNumber(val),
				tickValues: 5, //
			}}
			enableGridX={false}
			colors={line =>
				line.id === GRAPH_INTERVAL_TYPES.former
					? chroma(GRAPH_COLORS.comparisonData).brighten(0.3).hex()
					: chroma(GRAPH_COLORS.recentData).brighten(0.3).hex()
			}
			lineWidth={5}
			pointSize={1} // !TEMP - Only set constant pointSize while data is being interpolated
			// pointSize={chartSettings.pointSize(props.data)}
			pointColor={{ from: 'color' }}
			// pointSize={8}
			// pointColor='#fff'
			// pointBorderWidth={3}
			// pointBorderColor={{ from: 'serieColor', modifiers: [] }}
			// pointLabel='y'
			// pointLabelYOffset={-12}
			enableArea={true}
			areaBaselineValue={getDisplayRangeOffset(displayRange)}
			areaOpacity={0.7}
			crosshairType='cross'
			useMesh={true}
			tooltip={info => (
				<>
					{props.dynamicTooltip ? (
						<CustomTooltip
							{...info}
							maxValue={displayRange.max}
							minValue={displayRange.min}
							sensorInfo={props.sensorInfo}
							timeOptions={props.timeOptions}
						/>
					) : (
						<div
							style={{
								background: '#fff',
								borderRadius: '0.1rem',
								boxShadow: '0 0 0.1rem 0.05rem #0002',
								margin: '0',
								padding: '0.3rem 0.6rem',
							}}
						>
							{getFormattedNumber(info?.point?.data?.y) + ' ' + (props.sensorInfo?.unit || '')}
							<br />
							{info.point.serieId === GRAPH_INTERVAL_TYPES.current
								? formatDate(info.point.data.x, props.timeOptions.dateFormat)
								: formatDate(addDays(info.point.data.x, -props.timeOptions.days), props.timeOptions.dateFormat)}
							<div
								style={{ width: '100%', height: '0.3rem', background: info.point.serieColor, margin: '0.2rem 0 0.25rem 0' }}
							/>
						</div>
					)}
				</>
			)}
			legends={
				props.maxLegends === undefined || props.maxLegends >= (props.data?.length || 0)
					? [
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 135,
								translateY: -2,
								itemsSpacing: 2,
								itemDirection: 'left-to-right',
								itemWidth: 100,
								itemHeight: 20,
								itemOpacity: 1.0,
								symbolSize: 20,
								symbolShape: 'circle',
							},
						]
					: undefined
			}
			markers={getThresholdMarkers(props.data, props.sensorInfo, displayRange)}
			defs={getLinearGradients(displayRange)}
			fill={[{ match: '*', id: 'linearGradient' }]}
			layers={[
				'grid',
				'markers',
				'axes',
				'areas',
				'crosshair',
				'lines',
				layerProps => drawAlarmMark(layerProps, props.alarm, GRAPH_TYPES.line, props.timeOptions),
				'points',
				'slices',
				'mesh',
				'legends',
			]}
			{...(props.chartProps || {})}
		/>
	);
}

function CustomTooltip({ point, maxValue, minValue, sensorInfo, timeOptions }) {
	const threshold = (Math.abs(maxValue) - Math.abs(minValue)) / 2;
	const isAboveThreshold = point.data.y > threshold;

	const tooltipStyle = {
		background: '#fff',
		borderRadius: '0.1rem',
		boxShadow: '0 0 0.1rem 0.05rem #0002',
		margin: '0',
		padding: '0.3rem 0.6rem',
		position: 'absolute',
		transform: isAboveThreshold ? 'translateY(0)' : 'translateY(-100%)',
	};

	return (
		<div style={tooltipStyle}>
			{getFormattedNumber(point.data.y) + ' ' + (sensorInfo?.unit || '')}
			<br />
			{point.serieId === GRAPH_INTERVAL_TYPES.current
				? formatDate(point.data.x, timeOptions.dateFormat)
				: formatDate(addDays(point.data.x, -timeOptions.days), timeOptions.dateFormat)}
			<div style={{ width: '100%', height: '0.3rem', background: point.serieColor, margin: '0.2rem 0 0.25rem 0' }} />
		</div>
	);
}

/**
 * Nivo-barchart meant to visalize data in the history-tab of property-node
 * @param {Array} data : Data to be shown
 * @param {Object} sensorInfo : Information about the sensor to which the data belongs
 * @param {Array} yRange : Override the default yScale by forcing the specific range of Y-values to in focus, [min, max]
 * @param {Object} chartProps : Further chart-settings that should override any defaults
 * @param {Object (INTERVAL_VALUES)} timeOptions : Information about the time-scale being drawn
 */
function HistoryBarChart(props) {
	const suggestedDisplayRange = getDataDisplayRange(
		getDataValueRange(
			GRAPH_TYPES.bar,
			props.data || [],
			[GRAPH_INTERVAL_TYPES.current, GRAPH_INTERVAL_TYPES.former],
			props.alarm?.value
		)
	);
	const displayRange = {
		min: props.yRange?.[0] !== undefined ? props.yRange[0] : suggestedDisplayRange.min,
		max: props.yRange?.[1] !== undefined ? props.yRange[1] : suggestedDisplayRange.max,
	};

	return (
		<ResponsiveBar
			data={(props.data || []).map(dat => ({
				...dat,
				[GRAPH_INTERVAL_TYPES.current]:
					dat[GRAPH_INTERVAL_TYPES.current] === null
						? null
						: dat[GRAPH_INTERVAL_TYPES.current] - getDisplayRangeOffset(displayRange),
				[GRAPH_INTERVAL_TYPES.former]:
					dat[GRAPH_INTERVAL_TYPES.former] === null
						? null
						: dat[GRAPH_INTERVAL_TYPES.former] - getDisplayRangeOffset(displayRange),
			}))}
			minValue={displayRange.min > 0 ? 0 : displayRange.max < 0 ? displayRange.min - displayRange.max : displayRange.min}
			maxValue={
				displayRange.min > 0
					? displayRange.max - displayRange.min
					: displayRange.max < 0
						? (displayRange.max - displayRange.min) * 0.05 // ... * 0.05 for offset from top to avoid clipping
						: displayRange.max
			}
			xScale={{ type: 'time', format: TIME_FMT }}
			xFormat={`time:${TIME_FMT}`}
			keys={[GRAPH_INTERVAL_TYPES.current, GRAPH_INTERVAL_TYPES.former]}
			indexBy='Datum'
			margin={{ top: 0, right: 168, bottom: 40, left: 50 }}
			padding={0.4}
			innerPadding={1}
			groupMode='grouped'
			colors={bar =>
				bar.id === GRAPH_INTERVAL_TYPES.former
					? chroma(GRAPH_COLORS.comparisonData).brighten(0.3).hex()
					: chroma(GRAPH_COLORS.recentData).brighten(0.3).hex()
			}
			borderRadius={1}
			borderWidth={1.5}
			borderColor={{ from: 'color', modifiers: [['darker', '0.6']] }}
			tooltip={info => (
				<div style={{ margin: '0' }}>
					{`${getFormattedNumber(getDisplayRangeOffset(displayRange) + info.value)} ${props.sensorInfo.unit || ''}`} <br />
					{info.value === info.data[GRAPH_INTERVAL_TYPES.former]
						? formatDate(addDays(new Date(info.indexValue), -props.timeOptions.days), props.timeOptions.dateFormat)
						: formatDate(new Date(info.indexValue), props.timeOptions.dateFormat)}
					{<div style={{ width: '100%', height: '0.3rem', background: info.color, margin: '0.2rem 0 0.25rem 0' }} />}
				</div>
			)}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: value => formatDate(new Date(value), props.timeOptions.dateFormat || 'yyyy-MM-dd HH:mm'),
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
				legendPosition: 'middle',
				legendOffset: 32,
			}}
			axisLeft={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: props.sensorInfo.unit || '',
				legendPosition: 'middle',
				legendOffset: -40,
				format: val => getFormattedNumber(getDisplayRangeOffset(displayRange) + val),
			}}
			enableLabel={false}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 135,
					translateY: -2,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 1.0,
					symbolSize: 20,
				},
			]}
			markers={getThresholdMarkers(props.data, props.sensorInfo, displayRange)}
			defs={getLinearGradients(displayRange, [
				{ id: 'grad_recent', color: chroma(GRAPH_COLORS.recentData).darken(0.5).hex(), opacity: '1' },
				{ id: 'grad_comparison', color: chroma(GRAPH_COLORS.comparisonData).darken(0.5).hex(), opacity: '1' },
			])}
			fill={[
				{ id: 'grad_recent', match: { id: GRAPH_INTERVAL_TYPES.current } },
				{ id: 'grad_comparison', match: { id: GRAPH_INTERVAL_TYPES.former } },
			]}
			layers={[
				'grid',
				'axes',
				'markers',
				'bars',
				layerProps => drawAlarmMark(layerProps, props.alarm, GRAPH_TYPES.bar, displayRange, props.timeOptions),
				'legends',
				'annotations',
			]}
			animate={true}
			motionStiffness={60}
			motionDamping={15}
			{...(props.chartProps || {})}
		/>
	);
}

/**
 * Nivo-barchart meant to visalize data in the history-tab of property-node
 * @param {Array} data : Data to be shown
 * @param {Object} sensorInfo : Information about the sensor to which the data belongs
 * @param {Array} yRange : Override the default yScale by forcing the specific range of Y-values to in focus, [min, max]
 * @param {Object} chartProps : Further chart-settings that should override any defaults
 * @param {Object (INTERVAL_VALUES)} timeOptions : Information about the time-scale being drawn
 */
function HistoryBarCharts(props) {
	const suggestedDisplayRange = getDataDisplayRange(
		getDataValueRange(
			GRAPH_TYPES.bar,
			props.data || [],
			[GRAPH_INTERVAL_TYPES.current, GRAPH_INTERVAL_TYPES.former],
			props.alarm?.value
		)
	);
	const displayRange = {
		min: props.yRange?.[0] !== undefined ? props.yRange[0] : suggestedDisplayRange.min,
		max: props.yRange?.[1] !== undefined ? props.yRange[1] : suggestedDisplayRange.max,
	};

	return (
		<ResponsiveBar
			data={(props.data || []).map(dat => ({
				...dat,
				[GRAPH_INTERVAL_TYPES.current]:
					dat[GRAPH_INTERVAL_TYPES.current] === null
						? null
						: dat[GRAPH_INTERVAL_TYPES.current] - getDisplayRangeOffset(displayRange),
				[GRAPH_INTERVAL_TYPES.former]:
					dat[GRAPH_INTERVAL_TYPES.former] === null
						? null
						: dat[GRAPH_INTERVAL_TYPES.former] - getDisplayRangeOffset(displayRange),
			}))}
			minValue={displayRange.min > 0 ? 0 : displayRange.max < 0 ? displayRange.min - displayRange.max : displayRange.min}
			maxValue={
				displayRange.min > 0
					? displayRange.max - displayRange.min
					: displayRange.max < 0
						? (displayRange.max - displayRange.min) * 0.05 // ... * 0.05 for offset from top to avoid clipping
						: displayRange.max
			}
			xScale={{ type: 'time', format: TIME_FMT }}
			xFormat={`time:${TIME_FMT}`}
			keys={[GRAPH_INTERVAL_TYPES.current, GRAPH_INTERVAL_TYPES.former]}
			indexBy='Datum'
			margin={{
				top: props.margin?.top !== undefined ? props.margin.top : 0,
				right: props.margin?.right !== undefined ? props.margin.right : 168,
				bottom: props.margin?.bottom !== undefined ? props.margin.bottom : 40,
				left: props.margin?.left !== undefined ? props.margin.left : 50,
			}}
			padding={0.4}
			innerPadding={1}
			groupMode='grouped'
			colors={bar =>
				bar.id === GRAPH_INTERVAL_TYPES.former
					? chroma(GRAPH_COLORS.comparisonData).brighten(0.3).hex()
					: chroma(GRAPH_COLORS.recentData).brighten(0.3).hex()
			}
			borderRadius={1}
			borderWidth={1.5}
			borderColor={{ from: 'color', modifiers: [['darker', '0.6']] }}
			tooltip={info => (
				<div style={{ margin: '0' }}>
					{`${getFormattedNumber(getDisplayRangeOffset(displayRange) + info.value)} ${props.sensorInfo.unit || ''}`} <br />
					{info.value === info.data[GRAPH_INTERVAL_TYPES.former]
						? formatDate(addDays(new Date(info.indexValue), -props.timeOptions.days), props.timeOptions.dateFormat)
						: formatDate(new Date(info.indexValue), props.timeOptions.dateFormat)}
					{<div style={{ width: '100%', height: '0.3rem', background: info.color, margin: '0.2rem 0 0.25rem 0' }} />}
				</div>
			)}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: value => formatDate(new Date(value), props.timeOptions.dateFormat || 'yyyy-MM-dd HH:mm'),
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
				legendPosition: 'middle',
				legendOffset: 32,
			}}
			axisLeft={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: props.sensorInfo.unit || '',
				legendPosition: 'middle',
				legendOffset: -40,
				format: val => getFormattedNumber(getDisplayRangeOffset(displayRange) + val),
				tickValues: 5,
			}}
			enableLabel={false}
			markers={getThresholdMarkers(props.data, props.sensorInfo, displayRange)}
			defs={getLinearGradients(displayRange, [
				{ id: 'grad_recent', color: chroma(GRAPH_COLORS.recentData).darken(0.5).hex(), opacity: '1' },
				{ id: 'grad_comparison', color: chroma(GRAPH_COLORS.comparisonData).darken(0.5).hex(), opacity: '1' },
			])}
			fill={[
				{ id: 'grad_recent', match: { id: GRAPH_INTERVAL_TYPES.current } },
				{ id: 'grad_comparison', match: { id: GRAPH_INTERVAL_TYPES.former } },
			]}
			layers={[
				'grid',
				'axes',
				'markers',
				'bars',
				layerProps => drawAlarmMark(layerProps, props.alarm, GRAPH_TYPES.bar, displayRange, props.timeOptions),
				'legends',
				'annotations',
			]}
			animate={true}
			motionStiffness={60}
			motionDamping={15}
		/>
	);
}

/**
 * Nivo-linechart meant to visualize compiled data in the overview for a selected region
 * @param {Array} data : Data to be displayed
 * @param {Object} chartProps : Props that will be passed directly to the chart
 */
function AccumulatedChart(props) {
	const dataInfo = getDataValueRange(GRAPH_TYPES.line, props.data || []);

	return (
		<ResponsiveLine
			data={chartSettings.data(props.data)}
			margin={{ top: 0, right: 168, bottom: 40, left: 50 }}
			xScale={chartSettings.xScale}
			yScale={chartSettings.yScale(dataInfo)}
			curve='monotoneX'
			axisBottom={{
				orient: 'bottom',
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
			}}
			axisLeft={{
				orient: 'left',
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: '',
				format: val => getFormattedNumber(val),
			}}
			enableGridX={chartSettings.enableGridX}
			colors={line =>
				line.id === UNIT_CLASSIFICATIONS.electricity.label
					? colors.secondaryB
					: line.id === UNIT_CLASSIFICATIONS.water.label
						? colors.secondaryA
						: colors.secondaryF
			}
			lineWidth={5}
			pointSize={chartSettings.pointSize(props.data)}
			pointColor={{ from: 'color' }}
			enableArea={false}
			areaOpacity={0.5}
			crosshairType='cross'
			useMesh={true}
			tooltip={info => (
				<div
					style={{
						background: '#fff',
						borderRadius: '0.1rem',
						boxShadow: '0 0 0.1rem 0.05rem #0002',
						margin: '0',
						padding: '0.3rem 0.6rem',
					}}
				>
					{getFormattedNumber(info?.point?.data?.y) + ' ' + (info.point.data.unit || '')} <br />
					<div style={{ color: '#333' }}>{getFormattedNumber(info?.point?.data?.price) + ' kr'}</div>
					<div style={{ color: '#444' }}>{info.point.data.x}</div>
					{<div style={{ width: '100%', height: '0.3rem', background: info.point.serieColor, margin: '0.2rem 0 0.25rem 0' }} />}
				</div>
			)}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: -6,
					itemsSpacing: 2,
					itemDirection: 'left-to-right',
					itemWidth: 100,
					itemHeight: 20,
					itemOpacity: 1.0,
					symbolSize: 20,
					symbolShape: 'circle',
				},
			]}
			{...(props.chartProps || {})}
		/>
	);
}

/**
 * Nivo-linechart that can take an unlimited amount of lines to display next to each other
 * @param {Array} data : Data to be displayed
 * @param {Number} maxLegends : How many legends to allow before legends are disabled
 * @param {Object} margin : Margins applied to the Nivo-chart, specifying the amount of spacing around it and room for legends, etc., {top, right, bottom, left: Number (pixels)}
 * @param {Object} chartProps : Props that will be passed directly to the chart
 */
function MultiLineChart(props) {
	const dataInfo = getDataValueRange(GRAPH_TYPES.line, props.data || []);

	return (
		<ResponsiveLine
			data={chartSettings.data(props.data)}
			margin={{
				top: props.margin?.top !== undefined ? props.margin.top : 0,
				right: props.margin?.right !== undefined ? props.margin.right : 168,
				bottom: props.margin?.bottom !== undefined ? props.margin.bottom : 40,
				left: props.margin?.left !== undefined ? props.margin.left : 50,
			}}
			xScale={chartSettings.xScale}
			//yScale={chartSettings.yScale(dataInfo)}
			yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
			curve='monotoneX'
			axisBottom={{
				orient: 'bottom',
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
			}}
			axisLeft={{
				orient: 'left',
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: '',
				format: val => getFormattedNumber(val),
			}}
			enableGridX={chartSettings.enableGridX}
			colors={[
				GRAPH_COLORS.recentData,
				colors.secondaryF,
				colors.secondaryA,
				colors.secondaryB,
				colors.secondaryC,
				colors.secondaryE,
				colors.secondaryG,
			]}
			lineWidth={5}
			pointSize={chartSettings.pointSize(props.data)}
			pointColor={{ from: 'color' }}
			enableArea={false}
			crosshairType='cross'
			useMesh={true}
			tooltip={info =>
				info.point.data.location ? (
					<div
						style={{
							background: '#fff',
							borderRadius: '0.1rem',
							boxShadow: '0 0 0.1rem 0.05rem #0002',
							margin: '0',
							padding: '0.3rem 0.6rem',
						}}
					>
						{info.point.data.location.length > 15 ? (
							<div style={{ fontWeight: '450', color: '#444' }}>
								{info.point.data.location.slice(0, info.point.data.location.length / 2 + 1) + '...'} <br />
								{info.point.data.location.slice(info.point.data.location.length / 2 + 1)}
							</div>
						) : (
							<div style={{ fontWeight: '500', color: '#444' }}>{info.point.data.location}</div>
						)}
						<div style={{ color: '#444' }}>{getFormattedNumber(info?.point?.data?.y) + ' ' + (info.point.data.unit || '')}</div>
						<div style={{ color: '#444' }}>{info.point.data.x}</div>
						{
							<div
								style={{ width: '100%', height: '0.3rem', background: info.point.serieColor, margin: '0.2rem 0 0.25rem 0' }}
							/>
						}
					</div>
				) : null
			}
			legends={
				props.maxLegends === undefined || props.maxLegends >= (props.data?.length || 0)
					? [
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 120,
								translateY: interpolateNumber(props.data.length, { min: 6, max: 34 }, { min: -6, max: 16 }),
								itemsSpacing: interpolateNumber(props.data.length, { min: 6, max: 34 }, { min: 2, max: 0 }),
								itemDirection: 'left-to-right',
								itemWidth: 100,
								itemHeight: interpolateNumber(props.data.length, { min: 6, max: 34 }, { min: 20, max: 10 }),
								itemOpacity: 1.0,
								symbolSize: interpolateNumber(props.data.length, { min: 6, max: 34 }, { min: 20, max: 10 }),
								symbolShape: 'circle',
							},
						]
					: undefined
			}
			{...(props.chartProps || {})}
		/>
	);
}

/**
 * Nivo-barchart meant to visualize the sum of compiled data
 * @param {Array} data : Data to be displayed
 */
function AccumulatedSumChart(props) {
	const dataInfo = getDataValueRange(GRAPH_TYPES.line, props.data || []);

	return (
		<ResponsiveLine
			data={chartSettings.data(props.data)} // If no valid data exists an empty space will be rendered
			margin={{ top: 0, right: 168, bottom: 40, left: 50 }}
			xScale={chartSettings.xScale}
			yScale={chartSettings.yScale(dataInfo, true)}
			curve='monotoneX'
			axisBottom={{
				orient: 'bottom',
				tickSize: 0,
				tickPadding: 12,
				tickRotation: 30,
				legend: '',
			}}
			axisLeft={null}
			enableGridX={chartSettings.enableGridX}
			enableGridY={false}
			colors={['#ddd']}
			lineWidth={3}
			enablePoints={false}
			enableArea={true}
			areaOpacity={0.6}
			areaBaselineValue={chartSettings.getMinValue(dataInfo)}
			useMesh={false}
			isInteractive={false}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: -2,
					itemsSpacing: 2,
					itemDirection: 'left-to-right',
					itemWidth: 100,
					itemHeight: 20,
					itemOpacity: 1.0,
					symbolSize: 20,
					symbolShape: 'circle',
				},
			]}
		/>
	);
}

/**
 * Horizontally aligned Nivo-barchart
 * @param {Array} data : Data to visualize in the chart
 * @param {Array} colors : Colors for bars, from top to bottom, will loop around, [String, ...]
 * @param {String} unit : Unit in which the data is represented
 * @param {Object} chartProps : Additional props that will be passed directly to the chart
 */
function HorizontalBarChart(props) {
	const dataInfo = getDataValueRange(GRAPH_TYPES.bar, props.data || [], ['value']);

	return (
		<ResponsiveBar
			data={props.data || []} // If no valid data exists an empty space will be rendered
			// minValue above 0 causes visual bugs. Negative values are fine
			minValue={Math.min(dataInfo.min, 0)}
			maxValue={Math.ceil(dataInfo.max + 0.49)}
			keys={['value']}
			indexBy='label'
			colorBy='index'
			margin={{ top: 0, right: 1, bottom: 0, left: 100 }}
			padding={0.2}
			groupMode='stacked'
			layout='horizontal'
			colors={props.colors.slice(5 - props.data.reduce((sum, cur) => sum + (cur.value ? 1 : 0), 0))}
			borderRadius={3}
			borderWidth={1}
			borderColor={{ from: 'color', modifiers: [['darker', '0.5']] }}
			tooltip={info => {
				return (
					<div style={{ width: 'max-content' }}>
						<div>{`${props.valuePrefix ? props.valuePrefix + ' ' : ''}${getFormattedNumber(info?.value)} ${
							props.unit || ''
						}`}</div>
						<div style={{ color: '#444', fontSize: '95%' }}>{info.indexValue?.split(';')[0]}</div>
						<div style={{ width: '100%', height: '0.3rem', background: info.color, margin: '0.2rem 0 0.25rem 0' }} />
					</div>
				);
			}}
			axisBottom={null}
			axisLeft={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: '',
				legendPosition: 'middle',
				legendOffset: -40,
				renderTick: tick => (
					<text
						onClick={() => props.chartProps?.onClick?.(tick, true) || null}
						transform={`translate(${tick.x - 10},${tick.y})`}
						textAnchor='end'
						dominantBaseline='middle'
						style={{
							fill: '#333',
							fontSize: 11,
							cursor: 'pointer',
						}}
					>
						{getTruncatedStr(tick.value?.split(';')[0]?.split(': ')[1], 16, true)}
					</text>
				),
			}}
			enableLabel={false}
			animate={true}
			motionStiffness={60}
			motionDamping={15}
			{...(props.chartProps || {})}
		/>
	);
}

/**
 * Renders custom legends, consisting of a list of colored squares and accompanying labels
 * @param {Object} style : CSS-style options
 * @param {Array} legends : Array of objects containing info about legends to be displayed, [{label: String, color: String}, ...]
 */
function CustomLegends(props) {
	return (
		<div style={props.style}>
			{props.legends.map(legend => {
				return (
					// All size and positioning properties must be set to the same as the legends in the Nivo charts
					<div key={legend.label} style={{ display: 'flex', marginBottom: '2px', fontSize: '12px' }}>
						<div style={{ background: legend.color, width: '20px', height: '20px', marginRight: '8px' }} />
						{legend.label}
					</div>
				);
			})}
		</div>
	);
}

export {
	HistoryLineChart,
	HistoryBarChart,
	HistoryBarCharts,
	AccumulatedChart,
	MultiLineChart,
	AccumulatedSumChart,
	CustomLegends,
	HorizontalBarChart,
};
