import i18n from './connect-front/src/i18n';

i18n.addResourceBundle('sv', 'translation', {
	document: {
		daysPreSpace: ' dagar',
		underCaptial: 'Under',
		under: 'under',
		during: 'under',
		thisSpace: 'denna ',
		theseSpace: 'dessa ',
		this: 'denna',
		these: 'dessa',
		totalCost: 'Totalkostnad',
		cost: 'Kostnad',
		consumption: 'Konsumtion',
		period: '.',
		connectedBuildings: 'Uppkopplade fastigheter',
		totalAlarms: 'Totalt antal larm',
		nonAcknowledgedNotices: 'Antal ej kvitterade larm',
		totalEnergyUsage: 'Totalförbrukning (i kWh), per energityp',
		electricity: 'elektricitet',
		water: 'vatten',
		districtHeating: 'fjärrvärme',
		energyPrices: 'Energipriser i kr:',
		per: 'per',
		kwhperProperty: 'Elförbrukning (i kWh), per fastighet',
		m3PerProperty: 'Vattenförbrukning (i m³), per fastighet',
		districtHeatingkwhPerBuilding: 'Fjärrvärmeförbrukning (i kWh), per fastighet',
		electricityComparison: 'Elförbrukningningsjämförelse (i kWh), per fastighet',
		previousP: 'Föregående p.',
		currentP: 'Nuvarande p.',
		energyComparisonPerBuilding: 'Elförbrukningningsjämförelse, per fastighet',
		waterComparisonPerBuilding: 'Vattenförbrukningsjämförelse, per fastighet',
		current: 'Nuvarande',
		previous: 'Föregående',
		change: 'Förändring',
		induvidualSensors: 'Individuella sensorer',
		lower: 'Undre',
		upper: 'Övre',
		alarms: 'Larm',
		acknowledged: 'Kvitterad',
		alarmValue: 'Larmvärde',
		alarmThresholds: 'Larmtrösklar',
		previousAlarms: 'Tidigaste Larm',
		latestAlarm: 'Senaste Larm',
		charts: 'Analyskomponenter',
	},
	dates: {
		monday: 'måndag',
		tuesday: 'tisdag',
		wednessday: 'onsdag',
		thursday: 'torsdag',
		friday: 'fredag',
		saturday: 'lördag',
		sunday: 'söndag',
		january: 'januari',
		february: 'februari',
		march: 'mars',
		april: 'april',
		may: 'maj',
		june: 'juni',
		july: 'juli',
		august: 'augusti',
		september: 'september',
		october: 'oktober',
		november: 'november',
		december: 'december',
	},
	header: {
		dailyReport: 'Dagsrapport',
		weeklyReport: 'Veckorapport',
		monthlyReport: 'Månadsrapport',
		daysReport: '-dagarsrapport',
		to: ' till ',
	},
	aiPage: {
		energyTitle: 'Energi Rapport',
		property: 'Fastighet',
		circuit: 'Krets',
		totalEnergySavings: 'Total energibesparing',
		totalSavingsKWH: 'Total besparing',
	},
});

i18n.addResourceBundle('en', 'translation', {
	document: {
		days: 'days',
		underCaptial: 'Under',
		under: 'under',
		during: 'during',
		thisSpace: 'this ',
		theseSpace: 'these ',
		this: 'this',
		these: 'these',
		totalCost: 'Total cost',
		cost: 'Cost',
		consumption: 'Consumption',
		period: '.',
		connectedBuildings: 'Connected Buildings',
		totalAlarms: 'Total number of alarms',
		nonAcknowledgedNotices: 'Unacknowledged alarms',
		totalEnergyUsage: 'Total consumption (in kWh), per energy type', //'Total consumption (in SEK), per energy type',
		electricity: 'electricity',
		water: 'water',
		districtHeating: 'district heating',
		energyPrices: 'Energy prices in SEK:',
		per: 'per',
		kwhperProperty: 'Electricity consumption (in kWh), per property',
		kwhAllProperties: 'Electricity consumption (in kWh), all properties',
		m3PerProperty: 'Water consumption (in m³), per property',
		m3AllProperties: 'Water consumption (in m³), all properties',
		districtHeatingkwhPerBuilding: 'District heating consumption (in kWh), per building',
		districtHeatingkwhAllBuildings: 'District heating consumption (in kWh), all buildings',
		electricityComparison: 'Electricity consumption comparison (in kWh), per property',
		previousP: 'Previous p.',
		currentP: 'Current p.',
		energyComparisonPerBuilding: 'Electricity consumption comparison, per building',
		waterComparisonPerBuilding: 'Water consumption comparison, per building',
		current: 'Current',
		previous: 'Previous',
		change: 'Change',
		induvidualSensors: 'Induvidual sensors',
		lower: 'Undre',
		upper: 'Övre',
		alarms: 'Alarms',
		acknowledged: 'Acknowledged',
		alarmValue: 'Alarm value',
		alarmThresholds: 'Alarm thresholds',
		previousAlarms: 'Earliest alarm',
		latestAlarm: 'Latest alarm',
		charts: 'Analysis components',
	},
	dates: {
		monday: 'monday',
		tuesday: 'tuesday',
		wednessday: 'wednesday',
		thursday: 'thursday',
		friday: 'friday',
		saturday: 'saturday',
		sunday: 'sunday',
		january: 'january',
		february: 'february',
		march: 'march',
		april: 'april',
		may: 'may',
		june: 'june',
		july: 'july',
		august: 'august',
		september: 'september',
		october: 'october',
		november: 'november',
		december: 'december',
	},
	header: {
		dailyReport: 'Daily Report',
		weeklyReport: 'Weekly Report',
		monthlyReport: 'Monthly Report',
		daysReport: '-days report',
		to: ' to ',
	},
});

export default i18n;
